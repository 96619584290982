<template>
  <v-form ref="form" @keyup.native.enter="login()">
    <v-card class="mx-auto pt-10" elevation="0">
      <v-card-title>{{ this.$t("dialogs.loginDialog.title") }}</v-card-title>
      <v-card-text>
        <v-alert v-if="errorMessage != null" dense outlined type="error">{{
          errorMessage
        }}</v-alert>

        <v-text-field
          v-model="credentials.email"
          :label="this.$t('dialogs.loginDialog.email')"
          :rules="[
            () => !!credentials.email || $t('user.form.errors.emailRequired'),
          ]"
        ></v-text-field>
        <v-text-field
          v-model="credentials.password"
          type="password"
          :label="this.$t('dialogs.loginDialog.password')"
          :rules="[
            () =>
              !!credentials.password || $t('user.form.errors.passwordRequired'),
          ]"
        ></v-text-field>
        <v-btn
          text
          small
          @click="openForgotPasswordDialog()"
          :disabled="isSending"
        >
          {{ this.$t("dialogs.loginDialog.forgotPassword") }}
        </v-btn>
        <v-card-actions class="d-flex justify-end pt-0 mt-0 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="login()"
            :disabled="!credentials.email || !credentials.password"
            :loading="isSending"
            class="mr-auto mt-1"
            color="green"
          >
            <v-icon left>mdi-lock-open-variant-outline</v-icon>
            {{ this.$t("dialogs.loginDialog.connect") }}
          </v-btn>
          <v-btn
            color="#e31837"
            text
            @click="closeModal()"
            outlined
            class="mr-auto mt-1"
            :disabled="isSending"
          >
            <v-icon left>mdi-backup-restore</v-icon>
            {{ this.$t("dialogs.loginDialog.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      credentials: {},
      isSending: false,
      errorMessage: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    openForgotPasswordDialog() {
      this.closeModal();
      this.$store.commit("showModal", {
        componentName: "ForgotPasswordDialog",
      });
    },
    closeModal() {
      this.$store.commit("hideModal", null);
    },
    login() {
      this.dialog = true;
      this.isSending = true;
      axios
        .post(`${process.env.VUE_APP_BACKEND_URI}/auth/login`, {
          email: this.credentials.email,
          password: this.credentials.password,
        })
        .then((response) => {
          this.$store.commit("setUser", {
            id: response.data.data.id,
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            role: response.data.data.role,
            email: response.data.data.email,
            htc: response.data.data.htc,
          });

          this.$store.commit("setJwt", { jwt: response.data.token });
          if (response.data.data.htc != null) {
            var htc = response.data.data.htc;
            var filters = this.$store.state.filters;
            filters.htcId = htc?.id;
            filters.htcName = htc?.name;

            this.$store.commit("setFilters", filters);
          } else {
            this.$store.dispatch("loadDashboardData");
          }
          this.isSending = false;
          this.dialog = false;
          this.closeModal();
        })
        .catch((e) => {
          this.dialog = false;
          this.isSending = false;
          this.errorMessage = "Invalid username or password";
        });
    },
  },
};
</script>